export const en = {
    showroom: 'A : Showroom condition.\nVehicle in excellent condition that does not require any major repairs.',
    'drive-worthy': 'B : Drive-worthy condition.\nVehicle in good condition that requires minor/major repairs.',
    unsafe: 'C : Unsafe condition.\nVehicle in bad condition that requires major repairs.',
    tire: {
        front_driver_information: 'Front Driver Tire Information',
        front_passenger_information: 'Front Passenger Tire Information',
        rear_driver_information: 'Rear Driver Tire Information',
        rear_passenger_information: 'Rear Passenger Tire Information',
        apply: "Apply the first tire's data uniformly across all tires."
    },
    general: {
        account: {
            greeting: 'Hi',
            name: 'Name'
        },
        language: {
            en: 'English',
            fr: 'French'
        },
        cancel: 'Cancel',
        accept: 'Accept',
        ok: 'Alright!',
        confirm: 'Confirm',
        time: 'Time',
        yes: 'Yes',
        no: 'No',
        partially: 'Partially',
        edit: 'Edit',
        return: 'Return',
        quit: 'Quit',
        submit: 'Submit',
        next: 'Next',
        upload: 'Upload',
        modified: 'Modified',
        required: 'Required',
        invalid: 'Invalid',
        previous: 'Previous',
        welcome: 'Welcome! 🙏',
        refreshPrompt: 'No inspections found\n Swipe down the screen',
        back: 'Back',
        continue: 'Continue',
        display_name_message: "What's your name?",
        firstName: 'First Name',
        lastName: 'Last Name',
        here_you_go: 'Here you go!',
        close: 'Close',
        settings: 'Settings',
        update: 'Update',
        datesUnavailable: 'Unavailable Dates',
        selectStartDate: 'Start Date',
        selectEndDate: 'End Date',
        cancelChanges: 'Cancel Changes',
        set: 'Set',
        clear: 'Clear',
        summary: 'Summary',
        saveAndQuit: 'Submit',
        comment: 'Comment for',
        confirmedIssue: 'Confirmed Issue ?',
        fillForm: 'Please fill out all the form. All the sections on the left must be colored in red, orange or green',
        videoUploadingMessage: 'Video uploading in progress',
        Vehicle_grade: 'Vehicle Grade',
        acceptInspections: 'Accept Inspections',
        options: {
            good: 'Good',
            average: 'Average',
            bad: 'Bad',
            na: 'N/A',
            name: 'Options',
            comment: 'Comment',
            comment_mandatory: 'Comment is mandatory',
            photo_optional: 'Photo optional',
            issues: 'Issue(s) :'
        },
        showBadge: 'Show Badge',
        inspectorBadge: 'Inspector Badge',
        certifiedInspector: 'Certified CarInspector'
    },
    purchase: 'Purchase',
    errors: {
        disconnect: 'You have been disconnected for security reasons.',
        forbidden: "You don't have access to that resource.",
        'missing-infos': 'Please fill the required fields.',
        'wrong-password': 'Your password is invalid.',
        'page-not-found': {
            title: 'Page not found',
            body: 'We cannot find the page you are looking for. You can still use our tools!'
        },
        'not-found': 'The resource you are looking for cannot be found.',
        'invalid-email': 'The email is invalid.',
        'weak-password': 'Your password is weak.',
        error: 'An error occurred',
        form: {
            empty_multiple_choice: 'Please select one option',
            empty_text: 'Please describe the reason',
            invalid: 'The form is invalid'
        },
        invalidLogin: 'Incorrect Username or Password',
        invalidUser: 'Invalid Inspector ID: ',
        invalidZipCode: 'Not a valid zip code',
        invalidPhone: 'Not a valid phone number',
        invalidStartDate: 'Start Date Invalid',
        invalidEndDate: 'End Date Invalid',
        invalidDates: 'Select a valid start and end date',
        unverifiedEmail: 'Please validate your e-mail address on the e-mail we sent.',
        pendingCandidate:
            'Oops, you are set as an inactive inspector. Please contact the CarInspect team to set you as an active inspector.'
    },
    authentication: {
        email_already_in_use: 'This email is already in use.',
        connect: 'Connect',
        connect_helpText: {
            email: 'Enter your email address',
            password: 'Enter your password'
        },
        email: 'Email',
        loader: 'Authenticating...',
        login: 'Login',
        logout: 'Logout',
        password: 'Password',
        connect_long: 'Already have an account?',
        signup: 'Signup',
        signup_long: "Don't have an account?",
        confirmation: 'Please verify your email address.',
        password_confirmation: 'Confirmation',
        password_incorrect: "Password doesn't match",
        password_reset: 'Reset Password',
        password_confirmation_helpText: 'Confirm your password',
        'confirmation-page': {
            title: 'Please verify your email address.',
            'resend-code': 'Resend link',
            message: 'A link has been sent to your email address. Use it to verify your account.'
        },
        forgot_password: 'I forgot my password',
        password_modal: {
            title: 'Reset my password',
            confirm: 'Reset',
            email: 'Email',
            message: 'A reset link has been sent to your email!'
        },
        password_reset_successful: 'Your password has been successfully reset!',
        new_code_sent: 'A new code has been sent'
    },
    inspection: {
        start: 'Start Inspection',
        details: {
            title: 'Inspection Request',
            seller: {
                type: {
                    title: 'Seller Type',
                    owner: 'Owner',
                    dealership: 'Dealership',
                    dealer: 'Dealership',
                    private: 'Private',
                    business: 'Business'
                },
                title: 'Seller',
                name: "Seller's Name",
                dealership: "Dealership's Name"
            },
            client: {
                title: 'Client',
                name: "Client's Name",
                extraDemande: 'Extra Demand',
                callRequest: 'Call Request',
                lastName: 'Last Name',
                lastNamePrompt: "Enter the client's last name",
                firstName: 'First Name',
                firstNamePrompt: "Enter the client's first name",
                prefLang: 'Preferred Language',
                prefLangPrompt: "Enter the client's preferred language (fr/en)",
                telNumber: 'Telephone number',
                telNumberPrompt: "Enter the client's phone number",
                emailPrompt: "Enter the client's email address"
            },
            vehicle: {
                title: 'Vehicle',
                make: 'Make',
                makePrompt: 'Enter the vehicle make',
                model: 'Model',
                modelPrompt: 'Enter the vehicle model',
                year: 'Year',
                yearPrompt: 'Enter the vehicle model year',
                price: 'Price',
                pricePrompt: 'Enter the vehicle price',
                mileage: 'Mileage',
                mileagePrompt: 'Enter the vehicle mileage',
                carFax: 'CarFax URL',
                km: '{{ km }} km',
                amount: '$ {{ amount }}',
                components: 'Components to inspect',
                componentSubcomponent: 'Component - Subcomponent : ',
                descriptionIssue: 'Description of the issue : '
            },
            language: 'Preferred Language',
            phone: 'Phone Number',
            address: 'Address',
            apartment: 'Apt/unit',
            street_number: 'Street number',
            street: 'Street Name',
            city: 'City',
            zipcode: 'Postal Code',
            province: 'Province',
            warning: {
                clientIsSeller: 'The client is the seller.',
                requireCall: {
                    yes: 'Call&nbsp;<b>required</b>&nbsp;after inspection.',
                    no: 'Call&nbsp;<b>not required</b>&nbsp;after inspection.'
                }
            },
            error: {
                no_inspection: 'No inspection found. Please return to home page.'
            }
        },
        tabs: {
            all_inspections: 'Available Inspections',
            my_inspections: 'My Inspections',
            inspection_history: 'History'
        },
        adhoc_inspection: {
            title: 'Technical issues? Start an Ad hoc inspection here!',
            label: 'Inspection Form'
        },
        card: {
            details_button: 'Details',
            errors: {
                no_inspection: 'No inspection found'
            },
            expiration: {
                hour: 'Created {{ hours }} hour ago',
                min: 'Created {{ mins }} mins ago',
                expired: 'URGENT - Created {{ hours }} hour ago'
            }
        },
        mandate_page: {
            accept: 'Accept',
            confirm: 'Confirm',
            debut: 'Start the inspection',
            resume: 'Resume the inspection',
            removeAcceptedInspection: 'Remove from my inspections',
            enter_date: 'Enter the date and time of the inspection',
            enter_time: 'Enter the time of the inspection',
            confirm_date: 'Confirm inspection date?',
            badDateModal: {
                title: 'Invalid date',
                message: 'You must set a date that is greater than the current date and time.'
            },
            modifiedDateReason: {
                title: 'Postponement reason',
                prompt: 'Select a reason',
                meteo: 'Weather condition',
                buyer: "Buyer's request",
                seller: "Seller's request",
                inspector: "Inspector's request",
                other: 'Other',
                otherInput: 'Please specify the postponement reason'
            },
            reportModal: {
                title: 'Report a problem',
                selectResponse: 'Select a reason down below',
                chooseResponse: 'Choose a response',
                noResponse: 'Seller did not respond',
                contactLater: 'Seller will contact me later',
                rescheduleInspection: 'Reschedule the inspection',
                other: 'Other',
                alreadySold: 'The car is already sold',
                sellerRefusal: 'Seller refuses to have the car inspected',
                back: 'Back',
                otherInput: 'Please specify the problem you face',
                otherResponse: 'Reason for the problem',
                confirm: 'Confirm',
                problemReported: 'Problem reported',
                reason: 'Reason',
                null: 'None'
            },
            bodyAssessmentMessage: 'Please open the link in the extra request to fulfill the inspection'
        },
        addressModal: {
            title: 'Modify address',
            prompt: 'Address to be added',
            streetNumberPrompt: 'Enter the street number',
            streetNamePrompt: 'Enter the street name',
            cityPrompt: 'Enter the city name',
            provincePrompt: 'Select the province',
            zipCodePrompt: 'Enter the Postal Code'
        },

        clientModal: {
            title: 'Client Information'
        },
        vehicleModal: {
            title: 'Vehicle Information'
        },
        status: {
            undefined: 'Available',
            null: 'Available',
            waiting: 'Waiting',
            accepted: 'Accepted',
            confirmed: 'Scheduled',
            rescheduled: 'Rescheduled',
            started: 'Started',
            completed: 'Completed',
            cancelled: 'Cancelled',
            archived: 'Archived',
            inreview: 'In Review'
        },
        remove_status: {
            title: 'Inspection Removal',
            message: 'Are you sure you want to remove this inspection from My Inspections?',
            confirm: 'Confirm',
            cancel: 'Cancel'
        },
        status_dates: {
            created: 'Created',
            accepted: 'Accepted',
            inspection: 'Inspection',
            pending: 'Pending',
            schedule: 'To Be Scheduled'
        },
        problemReported: 'Problem reported',
        form: {
            error_message: 'Please fill the required fields',
            next: 'next',
            selectOption: 'Select all options that apply',
            type_prompt: 'What type of inspection would you like to create?',
            type_iA: 'Insurance',
            type_PSI: 'PSI',
            type_reg: 'Regular',
            placeholder: 'Enter the value',
            adHocPrompt: 'This is an Ad hoc inspection. Please notify CarInspect by contacting them at',
            adHocTitle: 'Ad hoc Inspection',
            image_reminder: 'Image must be in landscape mode'
        },
        type: {
            type: 'Type',
            psi: 'Psi',
            troubleshoot: 'Troubleshoot',
            complete: 'Complete',
            all: 'All'
        },
        exit_form: {
            title: 'Exit?',
            message: 'Are you sure you want to exit the form?\n Your progress will be saved.'
        },
        submit_form: {
            title: 'Form Completed!',
            message: 'Are you sure you have correctly filled out all the information?',
            clientCallPrompt: 'Please call the client by calling:',
            kiwizCallPrompt: 'Please inform CarInspect by calling:',
            confirm: 'Submit Form',
            troubleshootMessage:
                "Please write 'pending' in the final comment and send an email with the last 6 VIN digits as the subject and the final comment as the content to this email address: operations@carinspect.com.",
            pendingMessage: 'Write the last 6 digits of the VIN as the subject of the email.',
            remove_status: {
                cancel: 'Cancel',
                confirm: 'Confirm'
            }
        },
        resume_form: {
            title: 'Resume progress',
            message: 'Start from the question you left off last time?'
        },
        error_vin: 'VIN must be exactly 17 characters',
        help: {
            title: 'Instructions',
            message: 'How to verify this component:',
            Frame: 'Visual check: use a flashlight and bend under the vehicle on both sides. Watch out for the pickup: test the rigidity of the bed.',
            Subframe:
                'Visual check: use a flashlight and bend under the vehicle on both sides. Watch out for the pickup: test the rigidity of the bed.',
            Undercarriage_rust:
                'Visual check: use a flashlight and bend under the vehicle on both sides. Watch out for the pickup: test the rigidity of the bed.',
            Body_condition: 'Visual check for defects, rust, stone chips, etc.',
            Body_alignment:
                'Visual check: Compare the spacing between each body panel. Pay special attention to the front and rear bumpers: inspect thoroughly.',
            Body_seals: 'Visual check: search for collapsing, dried, or broken joints',
            Doors: 'Test opening and closing, hinges and mechanism',
            Trunk: 'Test opening and closing, hinges and mechanism.',
            Roof: 'Visual check for defects, rust, stone chips, etc.',
            Convertible_top:
                'Test opening and closing, mechanism and check for missing grease. Visual check: look for tears or defects in the fabric.',
            Paint_condition:
                'Visual check: general condition of paintwork and look for color differences or traces of new paint; run your finger along the edge of the hood and trunk to check for paint drips)',
            Fuel_tank_cap: 'Ensure easy opening and closing and tightness of cap',
            Front_windshield: 'Visual check for cracks, chips, opacity',
            Rear_windshield: 'Visual check for cracks, chips, opacity',
            Windows: 'Test opening and closing of all window buttons. Visual check for cracks, chips, opacity',

            Mirrors: 'Test operation of electric mirror from both sides. Visual check: cracks, chips, opacity',

            Wipers: 'Test different wiper speeds and windshield washer. Visual check: tears, rust, etc.',
            Position_lights: 'Visual checks: recording with a phone or using a mirror',
            Low_beam_lights: 'Visual checks: recording with a phone or using a mirror',
            High_beam_light: 'Visual checks: recording with a phone or using a mirror',
            Turn_signals: 'Visual checks: recording with a phone or using a mirror',
            Reverse_lights: 'Visual checks: recording with a phone or using a mirror',
            Brake_lights: 'Visual checks: recording with a phone or using a mirror',

            Tire_condition: 'Visual check: cracks, crazing, dents, etc.',

            Computer_diagnosis:
                'Use OBDII scanner and take photo or screenshot. Warning: mention ALL confirmed error codes.',
            Engine_oil_level:
                'Test with the car on a flat surface, remove the dipstick and wipe it clean before dipping it back into the oil tank and removing it again to check the level precisely.',
            Engine_oil_leaks:
                'Visual check: look for any leaks. Caution: if leaks are present, specify level 1, 2 or 3 and indicate source.',
            Transmission_leaks:
                'Visual check: look for leaks above and below the engine. Caution: if leaks are present, specify level 1, 2 or 3 and indicate source.',

            Need_brake_maintenance:
                'Visual check: use a light to check the condition of discs and pads. Test during the test drive. Caution: Be VERY conservative in your assessment.',
            Need_brake_replacement:
                'Visual check: use a light to check the condition of discs and pads. Test during the test drive. Caution: Be VERY conservative in your assessment.',
            Brake_fluid_condition:
                'Visual check: brake fluid level. Test fluid quality with appropriate tool. Caution: if there is a leak, specify level 1, 2 or 3.',
            Brake_fluid_leaks:
                'Visual check: hoses, bleed screws and entire brake system. Caution: brake fluid leaks may be very dangerous.',
            Transfer_case_leaks: 'Visual check: use a light. Caution: if leakage is present, specify level 1, 2 or 3.',
            Differential_leaks: 'Visual check: use a light. Caution: if leakage is present, specify level 1, 2 or 3.',

            Coolant_condition: 'Visual check: fluid level and quality using of a coolant tester.',
            Coolant_fluid_leaks:
                'Visual check: hoses, fluid flow, seals and radiator condition. Caution: if leaks are present, specify level 1, 2 or 3.',
            Air_filter_condition: 'Visual check: open the box and check the air filter.',

            Radiator_condition: 'Visual check for debris, rust. Specify if maintenance required.',
            Radiator_cap_sealing: 'Visual check: tightness and condition of seal.',
            Voltage:
                'Caution: Engine must be turned off. Connect voltmeter. Voltage between 12.4 and 13: green. Voltage between 11.7 and 12.4: orange. Voltage below 11.7: red.',
            Terminal: 'Visual check: corrosion, terminal oxidation, etc.',
            Cables: 'Visual check for tears or other damage.',
            Mounting: 'Visual check: corrosion. Test if battery is correctly attached.',
            Alternator:
                'Caution: Engine must be turned on. Connect the voltmeter. Voltage between 13V and 15V: green. Voltage below 13V: orange or red.',
            Cooling_fan_condition: 'Visual check: look for any leaks or damage.',
            Power_steering:
                'Visual check of the power steering fluid level and detect any noise or resistance in the steering during the road test.',

            V_belts:
                'Visual inspection of the accessory belt to ensure it is not dry, cracked, or overly tensioned. Specify if there are invoices justifying the replacement of the timing belt.',
            Hoses_and_pipes:
                'Visual inspection of hoses and all fluid passages for excessive dryness, cracks, leaks, or over-tension.',

            Shocks_and_bushings:
                'Visual inspection: apply weight to each suspension to perform a bounce test AND test during the road test to detect suspension noises.',
            Ball_joints_and_tie_rods:
                'Visual inspection: apply weight to each suspension to perform a bounce test AND test during the road test to detect suspension noises.',
            Hand_brake: 'Engage the electric or manual handbrake to test it.',
            CV_joints_and_axles: 'Test the Constant Velocity joints and axles during the road test.',

            Mufflers: 'Visual inspection: checking for leaks while the engine is running.',
            Catalytic_converter: 'Visual inspection: checking for leaks while the engine is running.',
            Pipes: 'Visual inspection: checking for leaks while the engine is running.',
            Sunroof:
                'Test the opening and closing, the mechanism, the various positions, and confirm that there is no lack of grease. Visual inspection: condition of the window.',
            Dashboard:
                'Check the interior of the vehicle (dashboard, seats, ceiling) to see if there are any serious stains or tears.',
            Sun_visors: 'Check if the sun visor opens correctly and see if the lamp functions.',
            Horn: 'Honk the horn.',
            Normal_engine_operating:
                'Observe and listen to the engine when cold and when warmed up for any knocking or ticking noises.',
            Normal_transmission_operating:
                'During the test drive, accelerate to at least 80 km/h to assess gear shifting smoothly and promptly without hesitation or strange noises.',
            Normal_gearbox_operating:
                'While the car is stationary, shift through P (Park), R (Reverse), N (Neutral), D (Drive) one by one, noting whether its easy or difficult to shift and if there are any unusual noises.',
            Normal_differential_operating:
                'Listen for any unusual noises from the differential during the test drive, especially when turning or at various speeds.',
            Normal_transfer_case_operating:
                'During the test drive, especially in vehicles equipped with 4WD or AWD, engage different modes (if applicable) and listen for any abnormal sounds or difficulties in shifting modes.',
            Normal_odometer_operating:
                'Record the mileage before and after the test drive to ensure that the odometer is operating correctly and logging miles as expected.',
            Normal_brake_system_perating:
                'During the road test, apply brakes at different intensities to ensure they respond promptly and effectively without pulling to one side or making unusual noises.',
            Normal_steering_operating:
                'During the test drive, check the steering for responsiveness and ease of use. There should be no excessive play or vibration, and the vehicle should not pull to one side.',
            Normal_suspension_operating:
                'Observe the vehicle handling and comfort during the test drive over various surfaces to ensure the suspension provides appropriate damping and support.',
            Normal_wheel_bearing_operating:
                'Listen for any humming or grinding noises from the wheel bearings during the test drive, which might indicate wear or damage.',
            Normal_Clutch_operating:
                'For manual transmission vehicles, test the clutch for a smooth engagement point and ensure there is no slipping during acceleration. For automatic transmissions, observe during the road test that gears engage smoothly without unexpected disengagement.',
            Governemental_sticker: 'Province sticker (Service Ontario, SAAQ, CVSE, Alberta, etc.) '
        }
    },
    inspectionRemoval: {
        conflict: 'Conflicting Appointments',
        reschedule: 'Client Rescheduling',
        equipment: 'Equipment Failure'
    }
};
