export const environment = {
  production: 'true',
  firebase: {
    apiKey: 'AIzaSyAktG_rbZmIF-vBTZg1v2kqAiPleOxky0I',
    authDomain: 'kiwiz-4bff3.firebaseapp.com',
    projectId: 'kiwiz-4bff3',
    storageBucket: 'kiwiz-4bff3.appspot.com',
    messagingSenderId: '11546753971',
    appId: '1:11546753971:web:5cd1a67bb4be82da2529c6',
    measurementId: 'G-94YRMF722L',
    persistence: 'undefined'
  },
  apiUrl: 'https://api.carinspect.com/v1.0',
  url: 'https://mobile.carinspect.com',
  application_token: 'ff3e771c-eb08-11ea-9366-5076afa7169c',
  gtm: 'undefined',
  google_maps_api_key: 'AIzaSyBBRduREYy8aYVshvLLRjKlqJ8Bez22LxU',
  sentryDsn: 'https://6080c39154ef12f12adb7fc1194393e0@o4508875146788864.ingest.us.sentry.io/4508976334503936',
};
