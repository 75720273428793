import { CUSTOM_ELEMENTS_SCHEMA, NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, Router } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { HttpHeaderInterceptor } from './interceptors/http-header-interceptor.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CustomTranslateLoader } from './loaders/custom-translate-loader';
import { Media } from '@ionic-native/media/ngx';
import { File } from '@ionic-native/file/ngx';
import * as Sentry from '@sentry/angular';
import { browserTracingIntegration } from '@sentry/angular';

// ✅ Initialize Sentry before the module loads
if (environment.sentryDsn) {
    Sentry.init({
        dsn: environment.sentryDsn,
        integrations: [browserTracingIntegration()],
        tracesSampleRate: 1.0
    });

    // ✅ Capture Global Errors
    window.onerror = function (message, source, lineno, colno, error) {
        console.error('❌ Global Error Captured:', message, error);
        Sentry.captureException(error || message);
    };

    // ✅ Capture Unhandled Promise Errors
    window.addEventListener('unhandledrejection', (event) => {
        console.error('❌ Unhandled Promise Rejection:', event.reason);
        Sentry.captureException(event.reason);
    });
}
@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot({
            driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
        }),
        AppRoutingModule,
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAuth(() => getAuth()),
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useClass: CustomTranslateLoader
            }
        })
    ],
    providers: [
        Media,
        File,
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpHeaderInterceptor,
            multi: true
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler() // ✅ Global Error Handling with Sentry
        },
        {
            provide: Sentry.TraceService, // ✅ Ensure tracing is enabled
            deps: [Router]
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true
        }
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
